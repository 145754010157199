<template>
	<div>
		<ContentHeader title="Laporan Pelayanan Klinik" subTitle="Laporan Transaksi Per Periode" url="laporan-pelayanan-klinik" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="card">
          <div class="card-header">
            <h3 class="card-title">Data Laporan</h3>
          </div>
          <div class="card-body" style="display: block;">
            <div class="row d-flex justify-content-end">
              <el-button @click="handleExportExcel" :loading="loadingExcel">Export Excel</el-button>	
            </div>
            <div class="row" v-if="toogleCabang">
              <div class="col-lg-4">
                <label>Pilih Cabang</label>
                <multiselect 
                  v-model="cabang_select_value"
                  deselect-label="Can't remove this value"
                  track-by="nama"
                  label="nama"
                  placeholder=""
                  :options="cabang"
                  :loading="loading_cabang"
                  :allow-empty="false"
                  @update:model-value="getDataResult"
                >
                </multiselect>
              </div>
            </div>
            <div class="row align-items-center mt-2">
              <div class="col-md-4 col-lg-2">
                <div v-if="cabangLogin.tipe.toLowerCase() != 'kecantikan'">
                  <span>Poli</span><br>
                  <el-select v-model="formFilter.poli" placeholder="Pilih Poli" style="width: 100%;" clearable @change="getDataResult()">
                    <el-option v-for="(row, index) in dataPoli" :key="index" :value="row.poli.id" :label="row.poli.nama"></el-option>
                  </el-select>
                </div>
              </div>
              <div class="col-md-4 col-lg-2">
                <div v-if="cabangLogin.tipe.toLowerCase() != 'kecantikan'">
                  <span>Layanan</span><br>
                  <el-select v-model="formFilter.layanan" placeholder="Pilih Layanan" style="width: 100%;" clearable @change="getDataResult()">
                    <el-option v-for="(row, index) in dataLayanan" :key="index" :value="row.id" :label="row.nama"></el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="row align-items-center mt-2">
              <div class="col-lg-2">
                <span>Jumlah Baris</span>
                <select class="custom-select form-control" v-model="page" @change="getDataResult">
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="45">45</option>
                  <option value="50">50</option>
                  <option value="1000000">Tampilkan Semua</option>
                </select>
              </div>
              <div class="col-lg-2">
                <span>Periode Awal</span>
                <input type="date" class="form-control" v-model="periode_awal">
              </div>
              <div class="col-lg-2">
                <span>Periode Akhir</span>
                <input type="date" class="form-control" v-model="periode_akhir">
              </div>
              <div class="col-lg-1 pt-4">
                <button class="btn btn-primary" @click="getDataResult">Filter</button>
              </div>
              <div class="col-lg-5">
                <span>Dokter</span>
                <multiselect 
                  v-model="dokter_select_value"
                  deselect-label="Can't remove this value"
                  track-by="nama_lengkap"
                  label="nama_lengkap"
                  placeholder=""
                  :custom-label="customSelectDokter"
                  :options="dokter"
                  :loading="loading_dokter"
                  :allow-empty="false"
                  @update:model-value="getDataResult"
                >
                </multiselect>
              </div>
            </div>
            <table class="table table-bordered table-hover mt-4">
              <thead>
                <tr>
                  <th scope="col" class="text-sm">NO</th>
                  <th scope="col" class="text-sm">TANGGAL</th>
                  <th scope="col" class="text-sm">KWITANSI</th>
                  <th scope="col" class="text-sm">PASIEN</th>
                  <th scope="col" class="text-sm">DOKTER</th>
                  <th scope="col" class="text-sm">ADMINISTRASI & JASA</th>
                  <th scope="col" class="text-sm">TINDAKAN</th>
                  <th scope="col" class="text-sm">OBAT</th>
                  <th scope="col" class="text-sm">PERCETAKAN</th>
                  <th scope="col" class="text-sm">ALKES</th>
                  <th scope="col" class="text-sm">TOTAL</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in data_result" :key="row.id">
                  <td class="text-sm" scope="row">{{ index + 1 }}</td>
                  <td class="text-sm">{{ row.created_at.slice(0, 10) }}</td>
                  <td class="text-sm">{{ row.nomor_kwitansi }}</td>
                  <td class="text-sm">{{ row.pasien.nama }}</td>
                  <td class="text-sm">{{ row.dokter.nama_lengkap }}</td>
                  <td class="text-sm">{{ format_nominal(row.biaya_administrasi_jasa) }}</td>
                  <td class="text-sm">{{ format_nominal(row.biaya_tindakan) }}</td>
                  <td class="text-sm">{{ format_nominal(row.biaya_obat) }}</td>
                  <td class="text-sm">{{ format_nominal(row.biaya_percetakan) }}</td>
                  <td class="text-sm">{{ format_nominal(row.biaya_alat_kesehatan) }}</td>
                  <td class="text-sm">{{ format_nominal(row.total_bayar) }}</td>
                </tr>
                <tr v-if="total != '' || total != 0">
                  <td class="font-weight-bold text-center" colspan="10">TOTAL</td>
                  <td class="text-sm font-weight-bold">{{ format_nominal(total) }}</td>
                </tr>
              </tbody>
            </table>
            <p class="text-center mt-2" v-if="memuat_data">Memuat Data...</p>
            <p class="text-center mt-2" v-if="cek_data">Data Masih Kosong</p>
          </div>
          <!-- /.card-body -->
          <div class="card-footer">
            <nav aria-label="...">
              <ul class="pagination">
                <li class="page-item" :class="{ disabled: pagination.current_page == pagination.from}" @click="paginateData(pagination.prev_page_url)">
                  <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
                </li>
                <li class="page-item" :class="{ active: pagination.current_page == row.label }" @click="paginateData(row.url)" v-for="(row, index) in links" :key="index"><a class="page-link" href="#">{{ row.label }}</a></li>
                <li class="page-item" :class="{ disabled: pagination.current_page == pagination.last_page}" @click="paginateData(pagination.next_page_url)">
                  <a class="page-link" href="#">Next</a>
                </li>
              </ul>
            </nav>
          </div>
          <!-- /.card-footer-->
        </div>
			</div>
		</div>
	</div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader2'
import Multiselect from '@suadelabs/vue3-multiselect'

import { ref, computed, onMounted, reactive, watch } from 'vue'
import store from '@/store'
import axios from 'axios'
import format_nominal from '@/format_nominal'
import Swal from 'sweetalert2'

const user = computed(() => store.getters['auth/user'])
const cabangLogin = computed(() => store.getters['auth/cabang'])
const data_result = ref([])
const cek_data = ref(false)
const page = ref(1000000)
const links = ref([])
const pagination = ref([])
const memuat_data = ref(false)
const periode_awal = ref('')
const periode_akhir = ref('')
const total = ref('')
const formFilter = reactive({
  poli: null,
  layanan: null
})


const getDataResult = async () => {
  data_result.value = []
  total.value = ''
  memuat_data.value = true
  cek_data.value = false
  let dokter_id = ''
  let tanggal_awal = ''
  let tanggal_akhir = ''
  let cabang_id = ''
  let poli = null
  let layanan = null

  formFilter.poli ? poli = formFilter.poli : poli = 'emptyParameter'
  formFilter.layanan ? layanan = formFilter.layanan : layanan = 'emptyParameter'

  if (cabang_select_value.value == '') {
    cabang_id = user.value.cabang_id
    getDokter(user.value.cabang_id)
  } else {
    cabang_id = cabang_select_value.value.id
    getDokter(cabang_select_value.value.id)
  }

  if (periode_awal.value == '' && periode_akhir.value == '') {
    tanggal_awal = 'emptyParameter'
    tanggal_akhir = 'emptyParameter'
  } else {
    tanggal_awal = periode_awal.value
    tanggal_akhir = periode_akhir.value
  }

  if (dokter_select_value.value == '') {
    dokter_id = 'emptyParameter'
  } else {
    dokter_id = dokter_select_value.value.id
  }

  let { data } = await axios.get(`api/laporan/getTransaksiPerPeriode/${poli}/${layanan}/${cabang_id}/${page.value}/${tanggal_awal}/${tanggal_akhir}/${dokter_id}`)

  if (data != 'kosong') {
    memuat_data.value = false
    cek_data.value = false
    data_result.value = data.data.data
    total.value = data.total
    links.value = data.data.links
    links.value.splice(0, 1)
    links.value.splice(links.value.length-1, 1)
    pagination.value = data.data
  } else {
    cek_data.value = true
    memuat_data.value = false
    data_result.value = []
  }
}

const paginateData = async (url) => {
  if (url != null) {
    data_result.value = []
    memuat_data.value = true

    let link_url = new URL(url)
    link_url = `${link_url.pathname}${link_url.search}`
    
    let { data } = await axios.get(`${link_url}`)

    
    if (data != 'kosong') {
      memuat_data.value = false
      data_result.value = data.data.data 
      total.value = data.total
      links.value = data.data.links
      links.value.splice(0, 1)
      links.value.splice(links.value.length-1, 1)
      pagination.value = data.data
    }
  }
}

const toogleCabang = ref(false)
const cabang_select_value = ref('')
const cabang = ref([])
const loading_cabang = ref(true)
const getCabang = async () => {
  let response = await axios.get(`api/cabang/getWhereId/${user.value.cabang_id}`)

  if (response.data.jenis == 'pusat') {
    toogleCabang.value = true
    let { data } = await axios.get(`api/cabang/getWhereCabang/${response.data.id}`)
    
    if (data != 'kosong') {
      cabang.value = data
      loading_cabang.value = false
    } else {
      cabang.value = []
      loading_cabang.value = false
    }
  }
}

const dokter = ref([])
const dokter_select_value = ref('')
const loading_dokter = ref(true)

const customSelectDokter = ({ nama_lengkap }) => {
  if (nama_lengkap.toLowerCase() != 'tanpa konsultasi') {
    return `Dr. ${nama_lengkap}`
  } else {
    return `${nama_lengkap}`
  }
}

const getDokter = async (id) => {
  loading_dokter.value = true
  let { data } = await axios.get(`api/karyawan/getAllWhereJabatan/${id}/dokter`)

  if (data != 'kosong') {
    dokter.value = data
    loading_dokter.value = false
  } else {
    dokter.value = []
    loading_dokter.value = false
  }
}

// POLI
const dataPoli = ref([])
const loading_poli = ref(true)
const cek_data_poli = ref(false)

const getPoli = async () => {
  let id = cabang_select_value.value.id ? cabang_select_value.value.id : user.value.cabang_id 
  let response = await axios.get(`api/poli/getWhereAktifRelationPoli/${id}`)

  if (response.data == 'kosong') {
          loading_poli.value = false
          cek_data_poli.value = true
          dataPoli.value = []
      } else {
          loading_poli.value = false
          cek_data_poli.value = false
        dataPoli.value = response.data
      }
}
watch(() => cabang_select_value.value.id, () => getPoli())
// END POLI

// LAYANAN
const dataLayanan = ref([])
const loading_layanan = ref(true)
const cek_data_layanan = ref(false)

const getLayanan = async () => {
  let response = await axios.get(`api/layanan/getWhereAktif`)

  if (response.data == 'kosong') {
          loading_layanan.value = false
          cek_data_layanan.value = true
          dataLayanan.value = []
      } else {
          loading_layanan.value = false
          cek_data_layanan.value = false
        dataLayanan.value = response.data
      }
}
// END LAYANAN

const loadingExcel = ref(false)
const handleExportExcel = async () => {
  loadingExcel.value = true
  let tanggal_awal  = periode_awal.value == '' && periode_akhir.value == '' ? 'emptyParameter' : periode_awal.value
  let tanggal_akhir = periode_awal.value == '' && periode_akhir.value == '' ? 'emptyParameter' : periode_akhir.value
  let dokter_id     = dokter_select_value.value == '' ? 'emptyParameter' : dokter_select_value.value.id 
  let poli          = formFilter.poli ? formFilter.poli : 'emptyParameter' 
  let layanan       = formFilter.layanan ? formFilter.layanan : 'emptyParameter' 

  await axios.get(`api/export/transaksi_per_periode/${user.value.cabang_id}/${tanggal_awal}/${tanggal_akhir}/${dokter_id}/${poli}/${layanan}`, { responseType: 'blob' })
  .then((response) => {
    console.log(response)
    let blob        = new Blob([response.data],{ type: 'application/vnd.ms-excel;charset=utf-8' })
    let url         = window.URL || window.webkitURL;
    let fileUrl     = url.createObjectURL(blob)
    let element     = document.createElement('a')
    element.href    = fileUrl;
    element.setAttribute('download',`laporan-transaksi-per-periode.xlsx`)
    document.body.appendChild(element)
    element.click()
    window.URL.revokeObjectURL(blob)
  })
  .catch(() => {
    Swal.fire({
      title: 'Gagal!',
      text: 'Gagal mengeksport data',
      icon: 'error',
      showConfirmButton: false,
      timer: 1500
    })	
  })
  .finally(() => {
    loadingExcel.value = false
  })
}

onMounted(() => {
  getCabang()
  getDataResult()
  getPoli()
  getLayanan()
})
</script>